<template>
  <v-row>
    <v-col cols="12">
      <contact-us-datatable></contact-us-datatable>
    </v-col>
  </v-row>
</template>

<script>

// demos
import ContactUsDatatable from './ContactUsDatatable.vue'

export default {
  components: {
    ContactUsDatatable,
  },
  setup() {
    return {}
  },
  watch: {
    $route() {
      document.title = 'PettyCash: Manager - Contact Us'
    },
  },
}
</script>
